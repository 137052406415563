@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");

* {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

h1 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 50px;
}

h2 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 32px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px black;
  padding: 0 10px;
  background-color: black;
}

h3 {
  text-align: center;
  font-family: "Gochi Hand", cursive;
  max-width: 340px;
  margin: 20px 20px 20px 0;
  font-size: 24px;
}

button {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  letter-spacing: 1px;
  background-color: pink;
  color: black;
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid black;
  font-weight: bold;
  text-transform: uppercase;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.insta {
  height: 40px;
  cursor: pointer;
}

button:hover {
  background-color: white;
  color: pink;
  border: 2px solid pink;
}

.hero {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.heroText {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroText span {
  color: pink;
  text-decoration: underline;
}

.heroImgWrapper {
  border-radius: 50%;
  overflow: hidden;
  border: 15px solid pink;
  width: 300px;
  height: 300px;
  display: flex;
}

.heroImg {
  filter: brightness(0.9);
  transition: ease-in-out 0.3s;
}
.heroImg:hover {
  filter: brightness(1);
}

/* span {
  font-size: 16px;
} */

.animator {
  width: 100%;
  height: 100%;
}

.pink {
  background-color: pink;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.spotty {
  background: url("../public/polka-dot-pattern.webp");
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: pink;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.imageWrapper span {
  position: absolute;
  bottom: 10px;
  color: black;
  font-family: "Gochi Hand", cursive;
}

/* img {
  width: 75%;
  height: auto;
  background-color: white;
  padding: 10px 10px 40px 10px;
  box-shadow: 0px 0px 8px rgb(0, 0, 0, 0.5);
} */

img {
}

.hoverH2 span {
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
  position: absolute;
  top: 50%;
}

.hoverH2 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hoverH2:active span {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  top: 55%;
}

@media (hover: hover) {
  .hoverH2:hover span {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    top: 57%;
  }
}

@media screen and (min-width: 600px) {
  /* img {
    width: 100%;
    padding: 15px 15px 50px 15px;
  } */

  /* span {
    font-size: 24px;
  } */

  h2 {
    font-size: 48px;
  }

  h3 {
    text-align: left;
  }

  .hero {
    flex-direction: row;
  }

  .heroText {
    margin-top: 0;
    align-items: start;
  }

  .heroImgWrapper {
    width: 400px;
    height: 400px;
  }
}
